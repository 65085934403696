@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind forms;

body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overscroll-behavior-y: none;
  overflow: none;
}

#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-grow: 1;
}

.spin {
  animation: spin 3s linear infinite;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  animation: spin 3s linear infinite;
  margin: auto;
}

.spinner {
  animation: spin 3s linear infinite;
}

.toast-enter {
  opacity: 0.01;
}

.toast-enter.toast-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.toast-exit {
  opacity: 1;
}

.toast-exit.toast-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.react-resizable-handle-w {
  transform: rotate(0deg);
  margin-left: 4px;
  background-image: url("data:image/svg+xml,%3Csvg stroke='white' fill='white' stroke-width='0' style='transform: rotate(90deg) scale(1);' viewBox='0 0 24 24' height='2em' width='2em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 9H4v2h16V9zM4 15h16v-2H4v2z'%3E%3C/path%3E%3C/svg%3E");
}

.marketing p a,
.marketing dd a {
  color: #7e3af2 !important;
  text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
  input {
    @apply text-gray-800;
  }
}

body .monaco-editor .lines-content,
body .monaco-editor .view-line,
body .monaco-editor .view-lines {
  user-select: auto;
  -ms-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -o-user-select: auto;
  -webkit-user-select: auto;
}

@layer base {
  .footer-button {
    @apply cursor-pointer inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-purple-600  focus:outline-none focus:border-purple-700  active:bg-purple-700 transition ease-in-out duration-150 h-15 justify-center;
  }

  .button {
    @apply cursor-pointer inline-flex items-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-purple-600 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700 transition ease-in-out duration-150 justify-center;
  }

  .button-small {
    @apply cursor-pointer inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
}

.app-border {
  @apply border-gray-300 dark-mode:border-gray-600;
}
